html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: Arial, Helvetica, sans-serif;

  background: url("./assets/MorganOh.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
}

#root,
.main {
  width: 100%;
  height: 100%;
}

.main {
  position: relative;
  background-image: linear-gradient(transparent 70%, rgba(0, 0, 0, 0.25));
}

@media screen and (min-width: 768px) {
  .main {
    background-image: none;
  }
}

.sig-wrapper,
.links {
  margin: 40px;
  color: #ffffff;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.5));
}
.sig-wrapper {
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.sig-wrapper h1 {
  opacity: 0.66;
  margin: 15px 0;
}

.links {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
  margin-right: 0;
}

.links a:hover {
  opacity: 1;
  border-left: 8px solid #ffffff;
  
}

.links a {
  transition: all 0.2s;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  display: block;
  opacity: 0.66;
  border-left: 4px solid #ffffff;
  padding: 8px 36px;
}
